export
function getParamVariables(url) {
  const regExp = /\{[+#./;?&]?([^{}:*]+)[^{}]*\}/g

  const variables = []

  while (true) {
    const match = regExp.exec(url)

    if (!match) {
      break
    }

    variables.push(...match[1].split(/\s*,\s*/))
  }

  return variables
}
