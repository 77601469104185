import { http } from 'vue-resource'

import { DataHolder } from '~/services/DataHolder'

import { getParamVariables } from '~/services/helpers/getParamVariables'

// eslint-disable-next-line import/first
import { defaults } from 'lodash-es'

export
class Remote {
  /** @return {Remote} */
  static get(name) {
    return (
      this._instances?.[name] ??
      (this._instance ??= new this())
    )
  }

  static register(name, remote) {
    (this._instances ??= {})[name] ??= remote
  }

  http(url, options) {
    options = defaults({ url }, options)

    options.params = defaults(
      options.params ?? {},
      DataHolder.get().pick(getParamVariables(url), options.context)
    )

    return http(options).then(
      response => {
        const { code, msg: message, data } = response.data

        if (!code) {
          return response.data
        }

        // eslint-disable-next-line yoda
        if (200 <= code && code <= 299) {
          return data
        }

        const error = new Error(message)

        error.code = code

        throw error
      },

      response => {
        let error = response

        if (response.data) {
          const { code, msg: message } = response.data

          // eslint-disable-next-line yoda
          if (code < 200 || 299 < code) {
            error = new Error(message)

            error.code = code
          }
        }

        throw error
      }
    )
  }

  use(interceptor) {
    http.interceptors.push(interceptor)
  }
}
