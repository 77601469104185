import { router } from '~/app/router'

import { camelCase, snakeCase, lowerCase, uniq, zipObject } from 'lodash-es'

/** @enum {number} */
const Stringifier = {
  Query: 1,
  Storage: 2,
}

export
class DataHolder {
  /** @return {DataHolder} */
  static get() {
    // eslint-disable-next-line no-return-assign
    return this._instance ??= new this()
  }

  router = router

  retrieve(name, vm) {
    for (const key of this._variants(name)) {
      let value

      if (vm && (value = this._normalize(vm[key])) !== null) {
        return value
      }

      // readonly
      if ((value = this._normalize(this.router.currentRoute.query[key], Stringifier.Query)) !== null) {
        return value
      }

      // for suck compatibility, prefer to localStorage rather then sessionStroage
      if ((value = this._normalize(window.localStorage.getItem(key), Stringifier.Storage)) !== null) {
        return value
      }

      if ((value = this._normalize(window.sessionStorage.getItem(key), Stringifier.Storage)) !== null) {
        return value
      }

      // readonly
      if ((value = this._normalize(window.Laravel?.[key])) !== null) {
        return value
      }
    }

    return null
  }

  save(name, value, vmOrEver = true) {
    if (typeof vmOrEver?.$set === 'function') {
      const vm = vmOrEver

      vm.$set(name, value)

      window.sessionStorage.setItem(name, value)
      window.localStorage.setItem(name, value)

      return
    }

    if (vmOrEver) {
      window.localStorage.setItem(name, value)

      return
    }

    window.sessionStorage.setItem(name, value)
  }

  remove(name, ever = true) {
    if (ever) {
      window.localStorage.removeItem(name)

      return
    }

    window.sessionStorage.removeItem(name)
  }

  pick(names, vm) {
    return zipObject(names, names.map(n => this.retrieve(n, vm)))
  }

  /** @private */
  _variants(name) {
    name = name.replace(/\s+/g, '-')

    return uniq([
      name, camelCase(name), snakeCase(name), lowerCase(name), lowerCase(name).replace(/\s+/g, ''),
    ])
  }

  /** @private */
  _normalize(value, stringifier) {
    if (typeof value === 'undefined') {
      return null
    }

    if (typeof value === 'string' && stringifier) {
      return this._parse(value, stringifier)
    }

    return value
  }

  /** @private */
  _parse(value, stringifier) {
    if (!value) {
      return null
    }

    if (value === 'undefined') {
      return null
    }

    if (value === 'true') {
      return true
    }

    if (value === 'false') {
      return false
    }

    if (Number(value).toString() === value) {
      return Number(value)
    }

    if (stringifier === Stringifier.Storage) {
      if (/^[[{]/.test(value)) {
        try {
          return JSON.parse(value)
        } catch (e) {}
      }
    }

    return value
  }
}
