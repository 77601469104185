import { SupervisorEditViewProvider } from '~/pages/supervisor/edit'
import { DeviceEditViewProvider } from '~/pages/device/edit'

import { ErpPayListView } from '~/pages/reports/erp-pay'
import { ErpCollectListView } from '~/pages/reports/erp-collect'

import { PatrolIndexViewProvider } from '~/pages/patrol/index'

import { EditDialogProvider } from '~/pages/partner/assets/edit-dialog'

import { Cooperation } from '~/widgets/Cooperation'

export
const routes = [
  {
    path: '/admin',

    children: [
      {
        path: 'supervisor',

        children: [
          {
            name: 'supervisor.edit',
            /* @override */
            path: ':id(\\d+)',

            provider: SupervisorEditViewProvider,

            // asset: {
            //   components: {
            //     Cooperation,
            //   }
            // },
          },
        ]
      },

      {
        path: 'network',

        children: [
          {
            name: 'network.edit',
            /* @override */
            path: ':id(\\d+)',

            // asset: {
            //   components: {
            //     Cooperation,
            //   }
            // },
          },
        ]
      },

      {
        path: 'device',

        children: [
          {
            name: 'device.edit',
            provider: DeviceEditViewProvider,
          },
        ]
      },

      {
        path: 'partner',

        children: [
          {
            name: 'partner',

            asset: {
              providers: {
                'siteCollaborate.Edit': EditDialogProvider,
                'counselorCollaborate.Edit': EditDialogProvider,
                'maintenanceCollaborate.Edit': EditDialogProvider,
              }
            },
          },
        ]
      },

      {
        path: 'patrol',
        children: [
          {
            name: 'patrol',
            provider: PatrolIndexViewProvider,
          },
        ]
      },

      {
        name: 'erp-pay',
        path: 'erp-pay',
        component: ErpPayListView,
      },

      {
        name: 'erp-collect',
        path: 'erp-collect',
        component: ErpCollectListView,
      },
    ]
  }
]
