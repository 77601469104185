import { router } from '~/app/router'
import { root } from '~/app/root'

import { routes } from '~/pages/routes'

export
function bootstrap(options) {
  options?.pre?.(router, root)

  router.addRoutes(routes)

  options?.post?.(router, root)
}
