import Vue from 'vue'

export
const { http, url } = Vue

export
const URL = window.URL ?? url.parse

// for compat, converting url template (${var}) to ({var})
// @see https://github.com/bramstein/url-template
url.transforms.unshift(options => {
  options.url = options.url.replace(/\$(?=\{)/g, '')
})
