import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { lastDateOfMonth, formatDate } from '~/commons/lang/dates'

import { ajax } from '~/services/ajax'

import { MainHeader } from '~/widgets/MainHeader'

@Component({
  components: {
    'main-header': MainHeader,
  },
})
export
class ErpPayListView extends ReactiveComponent {
  queryWord = ''
  querySupervisor = ''
  queryMonth = ''
  queryFormattedMonth = ''

  queryPageIndex = 1
  queryPageSize = 20

  supervisorOptions = []

  tableData = []

  mounted() {
    this.loadData()
  }

  loadData() {
    this.loadSupervisorOptions()
    this.loadPayListData()
  }

  @Watch('queryWord')
  @Watch('querySupervisor')
  @Watch('queryFormattedMonth')
  @Watch('queryPageIndex')
  @Watch('queryPageSize')
  loadPayListData() {
    ajax(
      '/api/index_payment',
      {
        method: 'POST',
        body: {
          title: this.queryWord,
          supervisor_id: this.querySupervisor,
          gmt_month: this.queryFormattedMonth,
          page: this.queryPageIndex,
          pageSize: this.queryPageSize,
          per_page: this.queryPageSize,
        },

        assumeJson: true,
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.tableData = data.data
        }
      }
    )
  }

  saveCheckStatus(item, status) {
    const message = `确定要${status === 1 ? '通过' : '拒绝'}该提现记录`

    this.$confirm(message, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      ajax(
        '/api/wx_check_out',
        {
          method: 'POST',
          body: {
            id: item.id,
            is_check: status,
          },

          assumeJson: true,
        },

        (error) => {
          if (error) {
            this.$message({ message: error.message || error, type: 'error' })
          } else {
            this.$message({ message: '操作成功' })
          }
        }
      )
    })
  }

  loadSupervisorOptions() {
    ajax(
      '/api/supervisor_list',
      {
        method: 'GET',
      },
      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.supervisorOptions = data
        }
      }
    )
  }

  exportData() {
    ajax(
      '/api/payment_export',
      {
        method: 'POST',
        body: {
          title: this.queryWord,
          supervisor_id: this.querySupervisor,
          gmt_month: this.queryFormattedMonth,
          page: this.queryPageIndex,
          pageSize: this.queryPageSize,
          per_page: this.queryPageSize,
        },

        assumeJson: true,
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.$message({ message: '导出成功', type: 'success' })
          this.download(data)
        }
      }
    )
  }

  download(url) {
    const anchor = document.createElement('a')

    anchor.download = ''
    anchor.href = url
    document.body.append(anchor)
    anchor.click()
    anchor.remove()
  }

  render() {
    return (
      <section class="erp-pay-page">
        <main-header>
          <span slot="header-title">ERP应付单</span>
        </main-header>

        <section style="margin-top: 20px;">
          <el-row>
            <el-col span={ 3 }>
              <el-input placeholder="关键字查询" value={ this.queryWord } onInput={ e => (this.queryWord = e) } suffix-icon="el-icon-search" clearable />
            </el-col>
            <el-col span={ 3 } offset={ 1 }>
              <el-select placeholder="经销店查询" value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } filterable clearable>
                {
                  this.supervisorOptions.map(item => (
                    <el-option label={ item.name } value={ item.id } />
                  ))
                }
              </el-select>
            </el-col>
            <el-col span={ 4 } offset={ 1 }>
              <el-date-picker placeholder="业务日期查询" value={ this.queryMonth } onInput={ e => (this.queryMonth = lastDateOfMonth(e)) } onChange={ e => (this.queryFormattedMonth = e) } type="month" format="yyyy-MM-dd" clearable />
            </el-col>
            <el-col span={ 3 } offset={ 12 - 3 } style="text-align: right;">
              <el-button type="primary" onClick={ e => this.exportData() }>导出应付单</el-button>
            </el-col>
          </el-row>
        </section>



        <section style="margin-top: 20px;" class="erp-pay-main">
          <el-table data={ this.tableData } border style="width: 100%">
            <el-table-column prop="gmt_month" label="业务日期" />
            <el-table-column prop="end_date" label="到期日" />
            <el-table-column prop="type" label="合作类型" />
            <el-table-column prop="divide_type" label="分成类型" />

            <el-table-column prop="supplier_name" label="结算组织" />
            <el-table-column prop="jxd" label="经销点" />
            <el-table-column prop="device_num" label="设备数量" />
            <el-table-column prop="revenue" label="营收" />
            <el-table-column prop="pric_eqty" label="占比/%" />
            <el-table-column label="固定金额" />
            <el-table-column prop="service_charge" label="服务费" />
            <el-table-column prop="flow_charge" label="流量费" />
            <el-table-column prop="tax_price" label="付款金额" />

            <el-table-column label="提现审核" width="150" scopedSlots={{
              default: scope => (
                scope.row.is_check === 0 ?
                  [
                    <el-button type="primary" size="small" plain onClick={ e => this.saveCheckStatus(scope.row, 1)}>通过</el-button>,
                    <el-button type="danger" size="small" plain onClick={ e => this.saveCheckStatus(scope.row, 2)}>拒绝</el-button>,
                  ] :
                scope.row.is_check === 1 ?
                  <span>已通过</span> :
                scope.row.is_check === 2 ?
                  <span>已拒绝</span> : null
              )
            }} />
          </el-table>

          <div class="pagination block" style="margin-top: 10px;">
            <el-pagination currentPage={ this.queryPageIndex } pageSize={ this.queryPageSize } layout="total, prev, pager, next, sizes, jumper" onCurrentChange={ e => (this.queryPageIndex = e) } onPrevClick={ e => (this.queryPageIndex = e) } onNextClick={ e => (this.queryPageIndex = e) } onSizeChange={ e => (this.queryPageSize = e) } on-current-change={ e => (this.queryPageIndex = e) } on-size-change={ e => (this.queryPageSize = e) } />
          </div>
        </section>
      </section>
    )
  }
}
